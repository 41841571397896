import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CButton } from "@coreui/react";
import { useTranslation } from "react-i18next";
import confirm from "reactstrap-confirm";
import { ROLES } from "src/Config/Constants";
import {
  DeleteOrderSelector,
  DeleteOrderTypes,
} from "src/Redux/DeleteOrderRedux";
import { loginStateSelector } from "src/Redux/LoginRedux";
import { alertError, alertSuccess } from "../../Utils/alert.error";
import "./font";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const DeleteOrderComponent = (props) => {
  const { id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector(loginStateSelector)?.data?.role;
  const [fetching, setFetching] = useState(false);
  const deleteOrder = (params) => {
    setFetching(true);
    dispatch({
      type: DeleteOrderTypes.DELETE_ORDER_REQUEST,
      params,
    });
  };

  const deleteOrderState = useSelector(DeleteOrderSelector);
  useEffect(() => {
    if (fetching && !deleteOrderState.fetching) {
      if (deleteOrderState.error) {
        alertError(t(deleteOrderState.error));
      } else if (deleteOrderState.data && deleteOrderState.data) {
        alertSuccess(t("delete_success"));
        navigate("/home/orders/list/");
      }
    }
  }, [
    deleteOrderState.fetching,
    fetching,
    deleteOrderState.error,
    deleteOrderState.data,
    t,
    id,
    navigate,
  ]);

  const deleteOrderConfirm = async (params) => {
    const result = await confirm({
      title: t("deleteOrder"),
      message: t("delete_order_confirm"),
      confirmText: t("delete"),
      confirmColor: "primary",
      cancelText: t("cancel"),
      cancelColor: "link text-danger",
    });
    if (result === true) {
      deleteOrder({ id });
    }
  };

  return (
    <>
      <CButton
        style={{ marginLeft: 100 }}
        color="danger"
        onClick={() => deleteOrderConfirm()}
      >
        {t("deleteOrder")}
      </CButton>
    </>
  );
};
DeleteOrderComponent.propTypes = {
  id: PropTypes.number.isRequired,
};
export default DeleteOrderComponent;
