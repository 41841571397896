import { call, put } from 'redux-saga/effects'
import DeleteOrderActions from '../Redux/DeleteOrderRedux'
import LoginActions from '../Redux/LoginRedux'
export function * deleteOrder (api, {params}) {
  try {
    const res = yield call(api, params)
    if (res === 'Unauthorized') {
      yield put(LoginActions.loginFailure())
      return
    }
    if (res.data) { 
      yield put(DeleteOrderActions.deleteOrderSuccess(res.data)) 
    } else {
      yield put(DeleteOrderActions.deleteOrderFailure(res))
    }
  } catch (error) {
    yield put(DeleteOrderActions.deleteOrderFailure(error.message))
  }
}
