export const ROLES_LABEL = {
  0: "user",
  1: "staff",
  9: "admin",
};
export const ROLES_COLOR = {
  0: "secondary",
  1: "primary",
  9: "danger",
};


export const ROLES = {
  USER: 0,
  STAFF: 1,
  ADMIN: 9,
};
