import { takeLatest, all } from 'redux-saga/effects'
import api from '../Services/Api'
/* ------------- Types ------------- */
import { DeleteOrderTypes } from '../Redux/DeleteOrderRedux'
import { TrackOrderTypes } from '../Redux/TrackOrderRedux'
import { RemoveOrderHistoryTypes } from '../Redux/RemoveOrderHistoryRedux'
import { RemoveAddressTypes } from '../Redux/RemoveAddressRedux'
import { GetAddressesTypes } from '../Redux/GetAddressesRedux'
import { UpdateUserTypes } from '../Redux/UpdateUserRedux'
import { ListUserTypes } from '../Redux/ListUserRedux'
import { ForgotPasswordTypes } from '../Redux/ForgotPasswordRedux'
import { UpdateOrderTypes } from '../Redux/UpdateOrderRedux'
import { GetOrderTypes } from '../Redux/GetOrderRedux'
import { ListOrderTypes } from '../Redux/ListOrderRedux'
import { CreateOrderTypes } from '../Redux/CreateOrderRedux'
import { RegisterTypes } from '../Redux/RegisterRedux'

import { StartupTypes } from '../Redux/StartupRedux'
import { LoginTypes } from '../Redux/LoginRedux'
/* ------------- Sagas ------------- */
import { deleteOrder } from './DeleteOrderSaga'
import { trackOrder } from './TrackOrderSaga'
import { removeOrderHistory } from './RemoveOrderHistorySaga'
import { removeAddress } from './RemoveAddressSaga'
import { getAddresses } from './GetAddressesSaga'
import { updateUser } from './UpdateUserSaga'
import { listUser } from './ListUserSaga'
import { forgotPassword } from './ForgotPasswordSaga'
import { updateOrder } from './UpdateOrderSaga'
import { getOrder } from './GetOrderSaga'
import { listOrder } from './ListOrderSaga'
import { createOrder } from './CreateOrderSaga'
import { register } from './RegisterSaga'
import { startup } from './StartupSagas'
import { login, logout } from './LoginSagas'
/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup, api),

    // tool generated sagas
    takeLatest(DeleteOrderTypes.DELETE_ORDER_REQUEST, deleteOrder, api.deleteOrder),
    takeLatest(TrackOrderTypes.TRACK_ORDER_REQUEST, trackOrder, api.trackOrder),
    takeLatest(RemoveOrderHistoryTypes.REMOVE_ORDER_HISTORY_REQUEST, removeOrderHistory, api.removeOrderHistory),
    takeLatest(RemoveAddressTypes.REMOVE_ADDRESS_REQUEST, removeAddress, api.removeAddress),
    takeLatest(GetAddressesTypes.GET_ADDRESSES_REQUEST, getAddresses, api.getAddresses),
    takeLatest(UpdateUserTypes.UPDATE_USER_REQUEST, updateUser, api.updateUser),
    takeLatest(ListUserTypes.LIST_USER_REQUEST, listUser, api.listUser),
    takeLatest(ForgotPasswordTypes.FORGOT_PASSWORD_REQUEST, forgotPassword, api.forgotPassword),
    takeLatest(UpdateOrderTypes.UPDATE_ORDER_REQUEST, updateOrder, api.updateOrder),
    takeLatest(GetOrderTypes.GET_ORDER_REQUEST, getOrder, api.getOrder),
    takeLatest(ListOrderTypes.LIST_ORDER_REQUEST, listOrder, api.listOrder),
    takeLatest(CreateOrderTypes.CREATE_ORDER_REQUEST, createOrder, api.createOrder),
    takeLatest(RegisterTypes.REGISTER_REQUEST, register, api.register),
    // Login
    takeLatest(LoginTypes.LOGIN_REQUEST, login, api.login),
    takeLatest(LoginTypes.LOGOUT_REQUEST, logout, api.logout),
  ])
}
