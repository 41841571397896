import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CRow,
  CTable,
} from "@coreui/react";
import { ListOrderSelector, ListOrderTypes } from "src/Redux/ListOrderRedux";
import { alertError } from "../../Utils/alert.error";
import { Link } from "react-router-dom";
import { convertValue } from './DetailOrder'

const fields = ['orderTransportNumber', 'receiverAddress', 'histories']

const LandingPageTracking = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const listOrderState = useSelector(ListOrderSelector)
  const [search, setSearch] = useState('')
  const _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchHandler()
    }
  }
  const searchHandler = useCallback(() => {
    dispatch({
      type: ListOrderTypes.LIST_ORDER_REQUEST,
      params: {
        orderTransportNumber: search,
      },
    })
  }, [dispatch, search])
  // useEffect(() => {
  //   if (isLogged) {
  //     searchHandler()
  //   }
  // }, [isLogged, searchHandler])

  useEffect(() => {
    if (listOrderState.error) {
      alertError(t(listOrderState.error.message))
    }
  }, [listOrderState, t])

  const orders = useMemo(() => {
    return listOrderState && listOrderState.data && listOrderState.data.data
      ? listOrderState.data.data
      : []
  }, [listOrderState])

  const renderOrder = (order) => {
    return (
      <tr key={order.orderTransportNumber}>
        {fields.map((field) => {
          switch (field) {
            case 'updated_at':
              return <td key={field}>{moment(order[field]).format('YYYY/MM/DD HH:mm')}</td>
            case 'orderTransportNumber':
              return (
                <td key={field}>
                  <Link to={`orders/tracking/${order[field]}`}>{order[field]}</Link>
                </td>
              )
            case 'receiverAddress':
              return (
                <td key={field}>
                  <CCol>
                    <p>
                      {t('address')}
                      {': '}
                      {order['receiverAddress']}
                    </p>
                    <p>
                      {t('company')}
                      {': '}
                      {order['receiverCompany']}
                    </p>
                    <p>
                      {t('country')}
                      {': '}
                      {order['receiverCountry']}
                    </p>
                  </CCol>
                </td>
              )
            case 'senderAddress':
              return (
                <td key={field}>
                  <CCol>
                    <p>
                      {t('address')}
                      {': '}
                      {order['senderAddress']}
                    </p>
                    <p>
                      {t('company')}
                      {': '}
                      {order['senderCompany']}
                    </p>
                    <p>
                      {t('name')}
                      {': '}
                      {order['senderName']}
                    </p>
                  </CCol>
                </td>
              )
            case 'histories':
              return (
                <td key={field}>
                  <CCol>
                    {order.histories &&
                      order.histories.map((each, index) => {
                        return (
                          <p
                            key={index}
                            style={{ borderTop: index > 0 ? '1px solid #ebedef' : 'none' }}
                          >
                            {convertValue('created_at', each.created_at)}{' '}
                            <span style={{ color: '#0d6efd' }}>
                              {t(`order_history_row_${each.status}`)}
                            </span>{' '}
                            {each.country}
                          </p>
                        )
                      })}
                  </CCol>
                </td>
              )
            default:
              return <td key={field}>{order[field]}</td>
          }
        })}
      </tr>
    )
  }

  return (
    <div className="animated fadeIn">
      <CRow>
        <CCol xs="12" md="12">
          <CRow style={{ marginBottom: 10 }}>
            <CCol>
              <CFormInput
                placeholder={t("orderTransportNumber")}
                value={search}
                onKeyPress={_handleKeyPress}
                onChange={(event) => setSearch(event.target.value)}
              />
            </CCol>
            <CCol md="2">
              <CButton
                color="success"
                onClick={() => searchHandler()}
                style={{ width: 100 }}
                disabled={listOrderState.fetching || !search}
              >
                {t("search")}
              </CButton>
            </CCol>
          </CRow>
          <CCol
            xs="12"
            md="12"
            style={{ display: "block", maxHeight: 800, overflowY: "auto" }}
          >
            {orders.length > 0 && (
              <CTable responsive>
                <thead>
                  <tr>
                    {fields.map((field) => (
                      <th
                        key={field}
                        style={{
                          width: field === "receiverAddress" ? "30%" : "",
                        }}
                      >
                        {t(field)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>{orders.map((order) => renderOrder(order))}</tbody>
              </CTable>
            )}
            {!listOrderState.fetching &&
              listOrderState.fetched &&
              !listOrderState.error &&
              orders.length === 0 && (
                <CFormLabel responsive>{t("not_found")}</CFormLabel>
              )}
          </CCol>
        </CCol>
      </CRow>
    </div>
  );
}

export default LandingPageTracking
