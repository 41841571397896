
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  deleteOrderRequest: ['params'],
  deleteOrderSuccess: ['data'],
  deleteOrderFailure: ['error'],
  clearData: null
})

export const DeleteOrderTypes = Types
export default Creators
export const DeleteOrderSelector = (state) =>  state.deleteOrder
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: {},
  error: null,
  fetching: false
})

/* ------------- Reducers ------------- */

export const deleteOrderRequest = state => state.merge({ fetching: true, error: null, data: null })

export const deleteOrderSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data: data })

export const deleteOrderFailure = (state, { error }) => state.merge({ fetching: false, error, data: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DELETE_ORDER_REQUEST]: deleteOrderRequest,
  [Types.DELETE_ORDER_SUCCESS]: deleteOrderSuccess,
  [Types.DELETE_ORDER_FAILURE]: deleteOrderFailure
})
